import React from 'react'
import styled from 'styled-components'
const StyledDiv = styled.div`
width: 22%;
min-width: 200px;
background:transparent;
display:flex;
justify-content:center;
align-items:center;
flex-direction: column;
padding:20px;

.image-class{
    width: 100%;
    max-width:100%;
}
.mainTitle{
    font-size: 1.5em;
    margin-bottom: 25px;
    margin-top: 25px;
    font-weight: 700;
    font-family: "Harmonia Sans", 'Nunito Sans',Arial, sans-serif;
    line-height: 1.2;  
    text-align: center;
}
.detailText{
    margin-bottom: 0;
    font-weight: 400;
    font-family: "Harmonia Sans", 'Nunito Sans', Arial, sans-serif;
    line-height: 1.6;  
    text-align: center;
}
`

const BenifitCard = ({imgSrc, title, subTitle, detailText}) =>{
    return(
        <StyledDiv>
            <img className="image-class" src={imgSrc} alt="Promote Cellular Renewal" className="lazyloaded"></img>
            <h3 className="mainTitle">{title}<br />{subTitle}</h3>
            <p className="detailText" >{detailText}</p>
        </StyledDiv>
    )
}

export default BenifitCard